// extracted by mini-css-extract-plugin
export var socialBlock = "cF_qY";
export var socialBlock__icon = "cF_q3";
export var socialBlock__iconWrapper = "cF_q2";
export var socialBlock__icon_facebook = "cF_q4";
export var socialBlock__icon_instagram = "cF_q7";
export var socialBlock__icon_linkedin = "cF_q5";
export var socialBlock__icon_telegram = "cF_q8";
export var socialBlock__icon_tiktok = "cF_q9";
export var socialBlock__icon_twitter = "cF_q6";
export var socialBlock__iconsWrapper = "cF_q1";
export var socialBlock__sprite = "cF_q0";
export var socialBlock__title = "cF_qZ";