// extracted by mini-css-extract-plugin
export var recentPost = "bV_l6";
export var recentPost__button = "bV_mk";
export var recentPost__postCard = "bV_l7";
export var recentPost__postCategory = "bV_mj";
export var recentPost__postClamp = "bV_mh";
export var recentPost__postDate = "bV_mc";
export var recentPost__postDescriprion = "bV_mg";
export var recentPost__postImage = "bV_l9";
export var recentPost__postInfo = "bV_mb";
export var recentPost__postLink = "bV_l8";
export var recentPost__postStats = "bV_md";
export var recentPost__postTitle = "bV_mf";
export var recentPost__title = "bV_ml";